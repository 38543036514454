import React, { useRef, useState, useEffect } from 'react';
import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import { getExams, getExamImage, addExamImage, deleteExamImage } from '@/api/index';
import { Btn, FTxt, Ic } from '@/components/basic/index';
import { FItemGroup, DateFormat, Toast } from '@/components/shared/index';
import ConfirmModal from '@/components/page-parts/ConfirmModal';
import style from '@/pages/report/Report.scss';
import { Validator, Rule } from '@/util/validate';
import ImageModal from './ImageModal';

const Exams = ({ studentId }) => {
  const [confirmModal, setConfirmModal] = useState({ show: false, title: '', content: '', type: '', buttonTitle: '' });
  const [image, setImage] = useState({ show: false, file: null, title: null });
  const [addFile, setAddfile] = useState({ name: '', mime: '', file: '' });
  const [uploadFile, setUploadFile] = useState([]);
  const [exams, setExams] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [examId, setExamId] = useState(null);
  const [fileId, setFileId] = useState(null);
  const inputRef = useRef(null);

  const handleShowConfirm = (type, title, content, buttonTitle, examId, fileId) => {
    setConfirmModal({
      ...confirmModal,
      show: true,
      title: title,
      content: content,
      type: type,
      buttonTitle: buttonTitle,
    });
    setExamId(examId);
    setFileId(fileId);
  };
  const handleCloseConfirm = () => setConfirmModal({ ...confirmModal, show: false, title: '', content: '', type: '' });

  const handleCloseModal = () => {
    setAddfile({ name: '', mime: '', file: '' });
    setExamId(null);
    setUploadFile([]);
    setShowModal(false);
  };
  const handleShowModal = (id) => {
    setExamId(id);
    setShowModal(true);
    setErrors({});
  };

  const validate = () => {
    const validate = new Validator(addFile);
    validate.exec('name', '画像名', [Rule.require()]);
    validate.exec('file', '画像', [Rule.require({ type: 'file' })]);
    setErrors(validate.getErrors());
    return validate.inValid();
  };

  const removeFile = () => {
    setUploadFile([]);
    setAddfile({ ...addFile, name: '', mime: '', file: '' });
  };

  const clickFileUpload = () => {
    inputRef.current.click();
  };

  const deleteFile = async () => {
    await deleteExamImage(studentId, examId, fileId);
    handleCloseConfirm();
    Toast.success('画像を削除しました');
    fillExams(studentId);
  };

  const onFileInputChange = (newFile) => {
    setLoading(true);
    if (newFile.target.files == null) return;
    const reader = new FileReader();
    const file = newFile.target.files[0];
    setUploadFile((current) => current.concat(file));
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const dataURI = reader.result;
      const base64Data = dataURI.split(',')[1] || dataURI;
      setAddfile({ ...addFile, name: '', mime: file.type, file: base64Data });
      setLoading(false);
    };
    if (inputRef.current) inputRef.current.value = '';
  };

  const showImage = async (examId, imageId, name, mime) => {
    const data = await getExamImage(studentId, examId, imageId);
    const reader = new FileReader();
    reader.onload = () => {
      const b64 = reader.result;
      setImage({ ...image, show: true, file: b64.replace('application/octet-stream', mime), title: name });
    };
    reader.readAsDataURL(data);
  };

  const closeImage = () => setImage({ ...image, show: false, file: null, title: null });

  const fillExams = async (id) => {
    const data = await getExams(id);
    setExams(data);
  };

  const onAddExamImage = async () => {
    if (validate()) return;
    try {
      await addExamImage(studentId, examId, addFile);
      Toast.success('画像を追加しました');
      handleCloseModal();
      fillExams(studentId);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fillExams(studentId);
  }, []);

  return (
    <Container fluid>
      <div style={{ marginTop: '1rem' }}>
        {exams.length === 0
          ? '登録されているテストはありません'
          : exams.map((r, i) => {
              return (
                <div key={i + 'test-n'}>
                  <Card className={[style.card]}>
                    <Card.Title>
                      <DateFormat date={r.date} />
                    </Card.Title>
                    <Card.Subtitle>{r.name}</Card.Subtitle>
                    <Card.Body>
                      <table className={style.scoreTable}>
                        <tbody>
                          {r.examSubjects.map((rr, ii) => {
                            return (
                              <React.Fragment key={ii + 'test'}>
                                <tr>
                                  <td className={style.subject}>{rr.subjectName}</td>
                                  <td style={{ fontSize: '0.5rem' }}>
                                    <span style={{ fontSize: '1.3rem', border: 'none' }}>{rr.score}</span>
                                    <span style={{ fontSize: '0.5rem', border: 'none', marginRight: '0.2rem' }}>
                                      /{rr.maxScore}
                                    </span>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                      <Row style={{ marginTop: '1rem' }}>
                        {r.examImages.map((rr, ii) => {
                          return (
                            <React.Fragment key={ii + 'exam'}>
                              <div className={style.imageLink}>
                                <a onClick={() => showImage(r.id, rr.id, rr.name, rr.mime)}>
                                  <Ic iName="faImage" />
                                  {rr.name}
                                </a>
                                <Btn
                                  prefixIcon="faTrashCan"
                                  variant
                                  color="danger"
                                  width="30"
                                  circle
                                  className="trash-btn"
                                  click={() => {
                                    handleShowConfirm(
                                      'danger',
                                      '削除確認',
                                      `${rr.name}を削除します。よろしいですか？`,
                                      '削除する',
                                      r.id,
                                      rr.id
                                    );
                                  }}
                                />
                              </div>
                            </React.Fragment>
                          );
                        })}
                        <Col>
                          <Btn
                            style={{ marginTop: '10px' }}
                            click={() => handleShowModal(r.id)}
                            children="新しい画像"
                            prefixIcon="faPlus"
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
      </div>
      <ImageModal show={image.show} title={image.title} file={image.file} onClose={closeImage} />
      <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>画像のアップロード</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addFile.file !== '' ? (
            <div style={{ display: 'inline', fontSize: '0.8rem' }}>
              <div>
                {uploadFile[0].name}
                <Btn
                  prefixIcon="faTrashCan"
                  variant
                  color="danger"
                  width="30"
                  circle
                  className="trash-btn"
                  click={() => removeFile()}
                />
              </div>
            </div>
          ) : (
            <FItemGroup errors={errors} paths={['file']}>
              <Col>
                <input
                  id="examFile"
                  type="file"
                  hidden
                  ref={inputRef}
                  onChange={onFileInputChange}
                  accept="image/*"
                ></input>
                <Btn outline click={!isLoading ? clickFileUpload : null} prefixIcon="faCamera">
                  {isLoading ? '読み込み中...' : '画像を選択'}
                </Btn>
              </Col>
            </FItemGroup>
          )}
          <FItemGroup label="画像名（例：国語問題用紙1）" className={style.spaceB} errors={errors} paths={['name']}>
            <Col>
              <FTxt
                disabled={false}
                value={addFile.name}
                change={(e) => setAddfile({ ...addFile, name: e.target.value })}
              />
            </Col>
          </FItemGroup>
        </Modal.Body>
        <Modal.Footer>
          <Btn
            click={async () => await onAddExamImage()}
            prefixIcon="faCloudArrowUp"
            children="アップロード"
            showSubmitLoading={true}
          />
        </Modal.Footer>
      </Modal>
      <ConfirmModal
        show={confirmModal.show}
        onClose={handleCloseConfirm}
        onSubmit={async () => await deleteFile()}
        title={confirmModal.title}
        content={confirmModal.content}
        footerButtonTitle={confirmModal.buttonTitle}
        showSubmitLoading={true}
      />
    </Container>
  );
};

export default Exams;
