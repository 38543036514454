import React from 'react';
import style from '@/components/contract/Contract.scss';

const TestPrivacyPolicy = () => {
  return (
    <>
      <div className={style.memo}>
        <div>・・・・</div>
        <div>
          <div>・・・・</div>
          <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>【お申し出先】</div>
          <div>
            <div>・・・</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TestPrivacyPolicy;
