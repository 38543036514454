import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import basicStyle from './Basic.scss';

const FormCheck = ({ size, label, disabled, value, checked, className, style, error, change }) => {
  const classNames = ['cst-check', basicStyle.glCheck, className];
  const inValid = error !== null && error !== undefined && error !== '';

  return (
    <>
      <Form.Check
        className={classNames}
        style={style}
        disabled={disabled}
        value={value}
        label={label}
        checked={checked}
        type="checkbox"
        id={label}
        onChange={change}
      />
      {inValid ? <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback> : <></>}
    </>
  );
};

FormCheck.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.any,
  checked: PropTypes.bool,
  change: PropTypes.func,
  error: PropTypes.string,
};

FormCheck.defaultProps = {
  size: 'sm',
  disabled: true,
  change: () => {},
  error: null,
};

export default FormCheck;
