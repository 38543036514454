import React from 'react';
import PropTypes from 'prop-types';
import cssStyle from './Shared.scss';
const Section = ({ first = false, title, children }) => {
  const Title = (v) => {
    return (v || '').split('\n').map((r, i) => {
      return (
        <React.Fragment key={i}>
          {r} <br />
        </React.Fragment>
      );
    });
  };
  const rows = (title || '').split('\n').length;
  return (
    <>
      <div className="section-title" style={{ marginTop: first ? '0px' : '2rem' }}>
        <div className={cssStyle.sectionBorder} style={{ top: rows === 1 ? '21px' : `${21 + 12}px` }} />
        <div className={cssStyle.sectionTitleWrapper}>
          <span className={cssStyle.title}>{Title(title)}</span>
        </div>
        <div className={cssStyle.sectionChildrenWrapper}>{children}</div>
      </div>
    </>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  first: PropTypes.bool,
};

Section.defaultProps = {
  first: false,
};

export default Section;
