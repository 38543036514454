import { apiBase } from '@/api/ApiBase';
import { auth, me } from '@/api/index';
import { getFromStorage, removeFromStorage, storeToStorage } from '@/store/storage';
import { tokenKey } from '@/constants/index';

export const login = async ({ userId, password }) => {
  try {
    const token = await auth({ userId, password });
    if (!token) {
      return false;
    }
    saveToken(token);
    apiBase.token = token;

    return true;
  } catch (_) {
    return false;
  }
};

export const saveToken = (token) => {
  storeToStorage(tokenKey, token);
};

export const logout = () => {
  removeFromStorage(tokenKey);
  apiBase.token = null;
};

export const getToken = () => {
  return getFromStorage(tokenKey, null);
};

export const restoreToken = () => {
  apiBase.token = getFromStorage(tokenKey, null);
};

export const isLogin = async () => {
  const token = getToken();
  if (!token) {
    return false;
  }
  try {
    apiBase.token = token;
    await me();
  } catch (_) {
    logout();
    return false;
  }
  return true;
};
