import { DItemGroup } from '@/components/shared';
import style from '@/components/contract/Contract.scss';
import React from 'react';

const GoodFee = () => {
  return (
    <>
      <DItemGroup label={'訪問家庭\n教師\n指導料(生徒1人あたり)'}>
        <table className={style.priceList}>
          <tbody>
            <tr>
              <th style={{ width: '180px' }}>①小学生・中学生</th>
              <td>1,800円/h</td>
            </tr>
            <tr>
              <th>②高校生 私立小中学生 中学受験生</th>
              <td>2,000円/h</td>
            </tr>
          </tbody>
        </table>
      </DItemGroup>
      <DItemGroup label={'訪問家庭\n教師\n指導料(2人同時指導)'}>
        <table className={style.priceList}>
          <tbody>
            <tr>
              <th style={{ width: '180px' }}>①+①の場合</th>
              <td>2人で 2,000円/h</td>
            </tr>
            <tr>
              <th>①+②の場合</th>
              <td>2人で 2,250円/h</td>
            </tr>
            <tr>
              <th>②+②の場合</th>
              <td>2人で 2,500円/h</td>
            </tr>
          </tbody>
        </table>
      </DItemGroup>
      <DItemGroup label={'オンライン\n指導料(生徒1人あたり)'}>
        <table className={style.priceList}>
          <tbody>
            <tr>
              <th style={{ width: '180px' }}>①小学生・中学生</th>
              <td>1,500円/h</td>
            </tr>
            <tr>
              <th>②高校生 私立小中学生 中学受験生</th>
              <td>1,600円/h</td>
            </tr>
          </tbody>
        </table>
      </DItemGroup>
      <DItemGroup label={'オンライン\n指導料(2人同時指導)'}>
        <table className={style.priceList}>
          <tbody>
            <tr>
              <th style={{ width: '180px' }}>①+①の場合</th>
              <td>2人で 1,600円/h</td>
            </tr>
            <tr>
              <th>①+②の場合</th>
              <td>2人で 1,800円/h</td>
            </tr>
            <tr>
              <th>②+②の場合</th>
              <td>2人で 2,000円/h</td>
            </tr>
          </tbody>
        </table>
      </DItemGroup>
    </>
  );
};

export default GoodFee;
