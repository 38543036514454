import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { me } from '@/api';
import { saveToken } from '@/util/authentication';
import { apiBase } from '@/api/ApiBase';

const EasyLogin = () => {
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    (async () => {
      try {
        apiBase.token = token;
        saveToken(token);
        await me();
      } finally {
        history.push('/');
      }
    })();
  }, [token, history]);

  return <></>;
};

export default EasyLogin;
