export const eventMgr = (() => {
  const events = {};
  return {
    addListener: (target, type, listener, capture, key) => {
      if (window.addEventListener) {
        target.addEventListener(type, listener, capture);
      } else if (window.attachEvent) {
        target.attachEvent('on' + type, listener);
      }
      events[key] = {
        target: target,
        type: type,
        listener: listener,
        capture: capture,
      };
    },
    removeListener: (key) => {
      if (key in events) {
        var e = events[key];
        if (window.removeEventListener) {
          e.target.removeEventListener(e.type, e.listener, e.capture);
        } else if (window.detachEvent) {
          e.target.detachEvent('on' + e.type, e.listener);
        }
      }
    },
  };
})();

export const isEmpty = (v) => v === null || v === undefined || v === '';

export const isNotEmpty = (v) => !isEmpty(v);

export const numberCommas = (v) => {
  const numberWithComma = new Intl.NumberFormat();
  return numberWithComma.format(v);
};
