import { getContractFileFor, getPurchaseOf } from '@/api';
import React, { useEffect, useState } from 'react';
import { yyyyMNddOf } from '@/util/date';
import { Col, Row } from 'react-bootstrap';
import { Btn } from '@/components/basic';
import { saveDownloadFile } from '@/util/file';

const ContractPDF = ({ purchaseId }) => {
  const [purchase, setPurchase] = useState();

  useEffect(() => {
    (async () => {
      await loadPurchase();
    })();
  }, [purchaseId]);

  const loadPurchase = async () => {
    const purchase = await getPurchaseOf({ id: purchaseId });
    setPurchase(purchase);
  };

  const showContractPdf = async () => {
    const contents = await getContractFileFor({ id: purchaseId });
    await saveDownloadFile(contents);
    await loadPurchase();
  };

  return (
    <>
      {purchase && purchase.customerContractPdfFileId && (
        <>
          <div style={{ margin: '10px 0 10px 0' }}>
            <Row>
              <Col xs={3}>
                <Btn isCustom outline prefixIcon="faFileLines" click={showContractPdf}>
                  確認
                </Btn>
              </Col>
              <Col xs={9}>
                <Row>
                  <Col xs={3}>作成日</Col>
                  <Col xs={9}>{yyyyMNddOf(purchase.customerContractPdfFileUpdateDate)}</Col>
                  <Col xs={3}>ご確認日</Col>
                  <Col xs={9}>
                    {purchase.showContractPdfFileDate ? (
                      yyyyMNddOf(purchase.showContractPdfFileDate)
                    ) : (
                      <span style={{ color: 'red' }}>未確認</span>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default ContractPDF;
