import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSettlement, getSettlements } from '@/api/index';
import { DateFormat } from '@/components/shared/index';
import { Form, Row, Col, Card } from 'react-bootstrap';
import Layout from '@/layout/Layout';
import { Btn, Ic } from '@/components/basic/index';
import style from './Report.scss';
import Settlement from '@/entity/Settlement';
import { numberCommas } from '@/util/util';

const ReportEdit = () => {
  const [page, setPage] = useState([0, 0]);
  const [settlement, setSettlement] = useState(new Settlement());
  const [settlements, setSettlements] = useState(null);

  const handlePrev = async () => {
    const n = page[0] - 1;
    if (n < 1) return;
    setPage([n, page[1]]);
    const data = await getSettlement(familyId, settlements[n - 1].id);
    setSettlement(data);
  };
  const handleNext = async () => {
    const n = page[0] + 1;
    if (n > page[1]) return;
    setPage([n, page[1]]);
    const data = await getSettlement(familyId, settlements[n - 1].id);
    setSettlement(data);
  };

  const { familyId, id } = useParams();

  useEffect(() => {
    (async () => {
      const resSettlements = await getSettlements();
      resSettlements.sort((a, b) => a.id - b.id);
      setSettlements(resSettlements);
      const famId = familyId === undefined ? resSettlements[resSettlements.length - 1].familyId : familyId;
      const settId = id === undefined ? resSettlements[resSettlements.length - 1].id : id;
      setPage([resSettlements.length, resSettlements.length]);
      const settlement = await getSettlement(famId, settId);
      setSettlement(settlement);
    })();
  }, []);

  return (
    <>
      <Layout>
        <div className={style.settlementFormWrapper}>
          <Card className={style.header}>
            <Row>
              <Col xs={3} style={{ textAlign: 'right' }}>
                <Btn isCustom className={style.pagerBtn} outline border={false} circle click={handlePrev}>
                  <Ic iName="faAnglesLeft" />
                </Btn>
              </Col>
              <Col xs={6}>
                <Row className={style.title}>
                  <Col>
                    {' '}
                    {page[0]}回 / {page[1]}回{' '}
                  </Col>
                </Row>
              </Col>
              <Col xs={3} style={{ textAlign: 'left' }}>
                <Btn isCustom className={style.pagerBtn} outline border={false} circle click={handleNext}>
                  <Ic iName="faAnglesRight" />
                </Btn>
              </Col>
            </Row>
          </Card>
          <Form noValidate style={{ marginTop: '1rem', textAlign: 'center' }}>
            <Card className={style.card}>
              <div>
                {id ? '精算依頼日：' : '精算完了日：'}
                <DateFormat date={settlement.requestDate} format="YYYY年MM月DD日" />
              </div>
              <div>精算状況：{settlement.isPaymented ? '清算済' : '未清算'}</div>
              <div>
                先生：{settlement.teacher.familyName}
                {settlement.teacher.firstName}
              </div>
              <Card className={style.totalAmount}>
                <Row>
                  <Col xs={4} style={{ fontSize: '15px', padding: '1rem 0 0 1rem', textAlign: 'left' }}>
                    合計金額
                  </Col>
                  <Col xs={6} style={{ fontSize: '30px', textAlign: 'right', padding: '0' }}>
                    {numberCommas(settlement.fee)}
                  </Col>
                  <Col xs={2} style={{ fontSize: '15px', padding: '1rem 1rem 0 0', textAlign: 'right' }}>
                    円
                  </Col>
                </Row>
              </Card>
              <div style={{ marginTop: '30px', fontSize: '15px' }}>料金の内訳</div>
              {settlement.reports.map((r, i) => {
                return (
                  <div key={i}>
                    <table className={style.settlementDetailTable}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'left' }} colSpan="2">
                            <DateFormat date={r.date} format="YYYY年MM月DD日" />
                            {`：${r.student.familyName}${r.student.firstName}`}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {r.fee ? (
                          <tr>
                            <td colSpan={1} style={{ textAlign: 'left' }}>
                              {`◇ 指導料(${r.time}分)`}
                            </td>
                            <td style={{ textAlign: 'right' }}>{numberCommas(r.fee)} 円</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {r.travelCost ? (
                          <tr>
                            <td colSpan={1} style={{ textAlign: 'left' }}>
                              ◇ 交通費
                            </td>
                            <td style={{ textAlign: 'right' }}>{numberCommas(r.travelCost)} 円</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </Card>
          </Form>
        </div>
      </Layout>
    </>
  );
};

export default ReportEdit;
