/**
 * ファイルをダウンロードする
 * @param response blobのレスポンス
 * @param defultFileInfo デフォルトのファイル情報
 */
export const saveDownloadFile = (response, defultFileInfo = new FileInfo({})) => {
  const blob = response.data;
  const contentDisposition = response.headers['content-disposition'];
  const fileNameMatch = contentDisposition ? contentDisposition.match(/filename\*=UTF-8''(.+)/) : [];
  const fileName = fileNameMatch.length > 1 ? decodeURI(fileNameMatch[1]) : defultFileInfo.fileName;

  if (window.navigator.msSaveOrOpenBlob) {
    // for IE,Edge
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const url = URL.createObjectURL(new Blob([blob], { type: defultFileInfo.contentType }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
};

export class FileInfo {
  constructor(params = {}) {
    this.fileName = params.fileName || '';
    this.contentType = params.contentType || '';
  }
}
