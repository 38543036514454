import { isNotEmpty } from '@/util/util';
import Student from './Student';
import Teacher from './Teacher';

export class Report {
  constructor(param = {}) {
    this.id = param.id || null;
    this.date = param.date || '';
    this.hour = isNotEmpty(param.hour) ? param.hour : '';
    this.minute = isNotEmpty(param.minute) ? param.minute : '';
    this.time = isNotEmpty(param.time) ? param.time : '';
    this.content = param.content || '';
    this.teacherEvaluation = param.teacherEvaluation || '';
    this.fee = isNotEmpty(param.fee) ? param.fee : '';
    this.travelCost = isNotEmpty(param.travelCost) ? param.travelCost : '';
    this.nextDate = param.nextDate || '';
    this.nextHour = isNotEmpty(param.nextHour) ? param.nextHour : '';
    this.nextMinute = isNotEmpty(param.nextMinute) ? param.nextMinute : '';
    this.nextInstructions = param.nextInstructions || '';
    this.isTurnIn = param.isTurnIn || false;
    this.isApproval = param.isApproval || false;
    this.familyEvaluation = param.familyEvaluation || '';
    this.reportProgress = param.reportProgress ? param.reportProgress.map((r) => new ReportProgress(r)) : [];
    this.teacher = param.teacher ? new Teacher(param.teacher) : new Teacher({});
    this.familyToTeacherComment = param.familyToTeacherComment || '';
    this.teacherToFamilyComment = param.teacherToFamilyComment || '';
    this.familyToHeadquartersComment = param.familyToHeadquartersComment || '';
    this.familyQuestionnaires = param.familyQuestionnairesJson || this.getInitalQuestionnaires();
    this.student = param.student ? new Student(param.student) : {};
  }
  mergeQuestionnaires() {
    const q = new Questionnaires();
    q.merge(this.familyQuestionnaires, window.env.familyQuestionnaires);
    this.familyQuestionnaires = q;
  }
  getInitalQuestionnaires() {
    const q = new Questionnaires();
    q.merge({}, window.env.familyQuestionnaires);
    return q;
  }
}

export class ReportProgress {
  constructor(param = {}) {
    this.id = param.id || null;
    this.code = param.code || '';
    this.textName = param.textName || '';
    this.gradeName = param.gradeName || '';
    this.subjectName = param.subjectName || '';
    this.page = isNotEmpty(param.page) ? param.page : '';
    this.maxPage = isNotEmpty(param.maxPage) ? param.maxPage : '';
    this.points = param.points || '';
    this.learningUnit = param.learningUnit || '';
  }
}

class Questionnaires {
  constructor() {
    this.version = '';
    this.inquiries = [];
  }
  merge(apiData, envData) {
    this.version = envData.version;
    if (apiData.version === envData.version) {
      this.inquiries = envData.inquiries.map((r) => new Inquiry(r, apiData));
      return;
    }
    this.inquiries = envData.inquiries.map((r) => new Inquiry(r, { inquiries: [] }));
  }
}

class Inquiry {
  constructor(envData, apiData) {
    this.inquiry = envData.inquiry || '';
    const d = apiData.inquiries.find((r) => r.inquiry === envData.inquiry);
    this.answer = d ? d.answer : '';
    this.answers = envData.answers || [];
  }
}

export class Exam {
  constructor(param = { examSubjects: [], examImages: [] }, isCopy = false) {
    this.id = param.id || null;
    this.date = param.date || '';
    this.name = param.name || '';
    this.examSubjects = param.examSubjects.map((r) => new ExamSubject(r));
    this.examImages = param.examImages.map((r) => new ExamImage(r));
    if (isCopy) {
      this.id = null;
      this.date = '';
      this.name = '';
      this.examSubjects.map((r) => {
        r.id = null;
        r.score = '';
        return r;
      });
      this.examImages = [];
    }
  }
}
export class ExamSubject {
  constructor(param = {}) {
    this.id = param.id || null;
    this.subjectName = param.subjectName || '';
    this.score = param.score || '';
    this.maxScore = param.maxScore || '';
  }
}

export class ExamImage {
  constructor(param = {}) {
    this.id = param.id || null;
    this.name = param.name || '';
    this.mime = param.mime || null;
  }
}

export class Record {
  constructor(param = { recordSubjects: [], recordImages: [] }, isCopy = false) {
    this.id = param.id || null;
    this.date = param.date || '';
    this.maxPoints = param.maxPoints || '';
    this.recordSubjects = param.recordSubjects.map((r) => new RecordSubject(r));
    this.recordImages = param.recordImages.map((r) => new RecordImage(r));
    if (isCopy) {
      this.id = null;
      this.date = '';
      this.recordSubjects.map((r) => {
        r.id = null;
        r.points = '';
        return r;
      });
      this.recordImages = [];
    }
  }
}
export class RecordSubject {
  constructor(param = {}) {
    this.id = param.id || null;
    this.subjectName = param.subjectName || '';
    this.points = param.points || '';
  }
}

export class RecordImage {
  constructor(param = {}) {
    this.id = param.id || null;
    this.name = param.name || '';
    this.mime = param.mime || null;
  }
}

export class TextBook {
  constructor(param = {}) {
    this.code = param.code || null;
    this.textName = param.textName || '';
    this.gradeName = param.gradeName || '';
    this.subjectName = param.subjectName || '';
  }
}

export default Report;
