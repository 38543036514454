import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '@/layout/Layout';
import Contract from '@/components/contract/Contract';
import { yyyyMNddOf } from '@/util/date';
import { FSel } from '@/components/basic/index';
import { getAllPurchase } from '@/api/index';
import style from './Purchase.scss';
import ContractPDF from '@/components/contract/ContractPDF';

const Top = () => {
  const [purchaseId, setPurchaseId] = useState(null);
  const [allPurchase, setAllPurchase] = useState([]);

  useEffect(() => {
    (async () => {
      const allPurchase = await getAllPurchase();
      setAllPurchase(allPurchase);
      setPurchaseId((allPurchase[0] || {}).id);
    })();
  }, []);

  return (
    <Layout>
      {purchaseId !== null && (
        <Container fluid className={style.wrapper}>
          <div>
            <Row className={style.header}>
              <Col className={style.pageTitle}>ご契約内容</Col>
            </Row>
          </div>
          <div>
            {allPurchase.length > 1 && (
              <>
                ご契約日
                <FSel
                  value={purchaseId}
                  disabled={false}
                  options={allPurchase.map((p) => {
                    return { label: yyyyMNddOf(p.contractDate, '(未契約)'), value: p.id };
                  })}
                  change={(e) => setPurchaseId(e.target.value)}
                />
              </>
            )}
          </div>
          {purchaseId && (
            <>
              <ContractPDF purchaseId={purchaseId} />
              <Contract purchaseId={purchaseId} />
            </>
          )}
        </Container>
      )}
    </Layout>
  );
};

export default Top;
