import React, { useEffect, useState } from 'react';
import { getServiceProvider } from '@/api/index';
import style from './Contract.scss';

const ServiceProvider = ({ serviceProvider = '' }) => {
  const [serviceProviderMaster, setServiceProviderMaster] = useState(null);

  useEffect(() => {
    (async () => {
      const sp = await getServiceProvider();
      const company = sp.filter((s) => {
        return s.name === serviceProvider;
      });
      setServiceProviderMaster(company[0] ? company[0] : null);
    })();
  }, []);

  return (
    <>
      {serviceProviderMaster && (
        <>
          <div className={style.memo}>
            <div>{serviceProviderMaster.displayName}</div>
            <div>{serviceProviderMaster.address}</div>
            <div>{serviceProviderMaster.serviceTime}</div>
            <div>代表者：{serviceProviderMaster.representer}</div>
          </div>
        </>
      )}
    </>
  );
};

export default ServiceProvider;
