import React from 'react';
import style from './TextBooks.scss';

const TextBooks = ({ textItems = [], purchase = {} }) => {
  const totalPrice = textItems.reduce((a, i) => {
    return a + i.price;
  }, 0);
  return (
    <>
      <div>ご購入テキスト</div>
      <table className={style.textTable}>
        <thead>
          <tr>
            <th rowSpan={2} style={{ width: '30px' }}></th>
            <th colSpan={3}>テキスト名</th>
            <th rowSpan={2} style={{ width: '80px' }}>
              価格
            </th>
          </tr>
          <tr>
            <th>種類</th>
            <th>学年</th>
            <th>科目</th>
          </tr>
        </thead>
        <tbody>
          {textItems.map((text, index) => {
            return (
              <React.Fragment key={index}>
                <tr className={index % 2 === 1 ? style.normalCol : undefined}>
                  <td rowSpan={2} className={style.numCol}>
                    {index + 1}
                  </td>
                  <td colSpan={3}>{text.text}</td>
                  <td rowSpan={2} className={style.numCol}>
                    {text.price}
                  </td>
                </tr>
                <tr className={index % 2 === 1 ? style.normalCol : undefined}>
                  <td>{text.type}</td>
                  <td>{text.grade}</td>
                  <td>{text.subjects}</td>
                </tr>
              </React.Fragment>
            );
          })}
          <tr>
            <td className={style.totalTitle} colSpan={4}>
              テキスト合計
            </td>
            <td className={style.numCol}>{totalPrice}</td>
          </tr>
          <tr>
            <td className={style.totalTitle} colSpan={4}>
              特別割引額
            </td>
            <td className={style.numCol}>{purchase.discount}</td>
          </tr>
          <tr>
            <td className={style.totalTitle} colSpan={4}>
              ①現金価格合計
            </td>
            <td className={style.numCol}>{totalPrice - purchase.discount}</td>
          </tr>
          <tr>
            <td className={style.totalTitle} colSpan={4}>
              ②分割・事務・決済手数料
            </td>
            <td className={style.numCol}>{purchase.splitCommission}</td>
          </tr>
          <tr>
            <td className={style.totalTitle} colSpan={4}>
              ③お支払総額(①+②)
            </td>
            <td className={style.numCol}>{totalPrice - purchase.discount + purchase.splitCommission}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default TextBooks;
