import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createStore from '@/store/Store';
import { restoreToken } from '@/util/authentication';
import App from './App';
import './index.scss';

const { company } = window.env;
document.title = company.brandName;

const history = createBrowserHistory();
const store = createStore(history);

restoreToken();

document.body.addEventListener(
  'touchstart',
  (e) => {
    if (e.touches && e.touches.length > 1) {
      e.preventDefault();
    }
  },
  { passive: false }
);
document.body.addEventListener(
  'touchmove',
  (e) => {
    if (e.touches && e.touches.length > 1) {
      e.preventDefault();
    }
  },
  { passive: false }
);

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App history={history} />
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
render();
