import Report from './Report';
class Student {
  constructor(param = {}) {
    this.id = param.id || null;
    this.familyName = param.familyName || '';
    this.firstName = param.firstName || '';
    this.gender = param.gender || '';
    this.report = param.report ? new Report(param.report) : new Report({});
  }

  fullName() {
    return `${this.familyName} ${this.firstName}`;
  }
}

export default Student;
