import React from 'react';
import { Route, Switch } from 'react-router';
import Login from '@/pages/Login';
import Purchase from '@/pages/purchase/Purchase';
import ReportTop from '@/pages/report/ReportTop';
import Report from '@/pages/report/Report';
import Password from '@/pages/Password';
import Reset from '@/pages/Reset';
import VerifyReset from '@/pages/VefifyReset';
import Settlement from '@/pages/report/Settlement';
import EasyLogin from '@/pages/EasyLogin';

export const isNoNeedLogins = (location) => {
  return (
    ['/login', '/password', '/verifyReset', '/reset'].filter((l) => {
      return l === location;
    }).length > 0
  );
};

const routes = (
  <div>
    <Switch>
      <Route exact path="/" component={Purchase} />
      <Route path="/purchase/current" component={Purchase} />
      <Route path="/purchase/:id" component={Purchase} />
      <Route path="/reportTop" component={ReportTop} />
      <Route path="/report/:id" component={Report} />
      <Route path="/:familyId/settlement/:id" component={Settlement} />
      <Route path="/settlement" component={Settlement} />
      <Route path="/login" component={Login} />
      <Route path="/password" component={Password} />
      <Route path="/verifyReset/:resetToken" component={VerifyReset} />
      <Route path="/reset" component={Reset} />
      <Route exact path="/mo4eit8EahYo5eig/:token" component={EasyLogin} />
    </Switch>
  </div>
);

export default routes;
