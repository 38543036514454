import React, { useState } from 'react';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';
import { Container, Form, Row, Col } from 'react-bootstrap';
import Layout from '@/layout/Layout';
import { login } from '@/util/authentication';
import style from './Login.scss';
import { FTxt, FPass, Btn } from '@/components/basic/index';
import { FItemGroup } from '@/components/shared/index';

const Login = () => {
  const history = useHistory();
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    const successLogin = await login({ userId, password });
    if (successLogin) {
      history.push('/');
    } else {
      toast('メアドもしくはパスワードが違います');
    }
  };

  const toResetPassword = () => {
    history.push('/password');
  };

  return (
    <Layout>
      <Container fluid className={style.loginWrapper}>
        <div className={style.mainTitle}>家庭専用ページ</div>
        <Form noValidate className={style.form}>
          <FItemGroup label="ID">
            <FTxt error="" change={(e) => setUserId(e.target.value)} disabled={false} />
          </FItemGroup>
          <FItemGroup label="パスワード">
            <FPass error="" change={(e) => setPassword(e.target.value)} disabled={false} />
          </FItemGroup>
          <Row className={style.footer}>
            <Col>
              <Btn click={handleLogin} prefixIcon="faArrowRightToBracket" showSubmitLoading={true}>
                ログイン
              </Btn>
            </Col>
          </Row>
        </Form>
        <div className={style.passwordInformation}>
          パスワードの初回登録と、パスワードを忘れた方は
          <a onClick={toResetPassword} className={style.link}>
            コチラ
          </a>
        </div>
      </Container>
    </Layout>
  );
};

export default Login;
