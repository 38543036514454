import React from 'react';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'react-bootstrap';
import routes from '@/routes/index';

const App = ({ history }) => {
  return (
    <>
      <ConnectedRouter history={history}>
        <ThemeProvider>{routes}</ThemeProvider>
      </ConnectedRouter>
    </>
  );
};

export default App;
