class Teacher {
  constructor(param = {}) {
    this.id = param.id || null;
    this.familyName = param.familyName || '';
    this.firstName = param.firstName || '';
    this.familyNameKana = param.familyNameKana || '';
    this.firstNameKana = param.firstNameKana || '';
    this.gender = param.gender || '';
    this.birthDate = param.birthDate;
    this.age = param.age;
    this.zip1 = param.zip1 || '';
    this.zip2 = param.zip2 || '';
    this.prefecture = param.prefecture || '';
    this.address1 = param.address1 || '';
    this.address2 = param.address2 || '';
    this.tel = param.tel || '';
    this.mail = param.mail || '';
    this.mobileTel = param.mobileTel || '';
    this.university = param.university || '';
    this.major = param.major || '';
    this.schoolYear = param.schoolYear || '';
    this.station = param.station || '';
    this.highSchool = param.highSchool || '';
    this.zip1Family = param.zip1Family || '';
    this.zip2Family = param.zip2Family || '';
    this.prefectureFamily = param.prefectureFamily || '';
    this.address1Family = param.address1Family || '';
    this.address2Family = param.address2Family || '';
    this.telFamily = param.telFamily || '';
    this.examExperience = param.examExperience || '';
    this.teachExperience = param.teachExperience || '';
    this.allergy = param.allergy || '';
    this.teachDays = param.teachDays || '';
  }
}

export default Teacher;
