import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getStudent } from '@/api/index';
import { Tabs, Tab } from 'react-bootstrap';
import { Ic } from '@/components/basic/index';
import Student from '@/entity/Student';
import Layout from '@/layout/Layout';
import Reports from '@/components/page-parts/Reports';
import ReportList from '@/components/page-parts/ReportList';
import Exams from '@/components/page-parts/Exams';
import Records from '@/components/page-parts/Records';
import style from './Report.scss';

const ReportEdit = () => {
  const [student, setStudent] = useState(new Student());
  const [loading, setLoading] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [selectReportId, setSelectReportId] = useState(null);

  const { id } = useParams();

  useEffect(async () => {
    const _student = await getStudent();
    setStudent(_student.filter((r) => String(r.id) === id)[0]);
    setLoading(true);
  }, []);

  const toReportDetail = (reportId) => {
    setSelectReportId(reportId);
    setShowEditor(true);
  };
  const close = () => {
    setShowEditor(false);
  };
  const ListOrEditor = () => {
    return showEditor ? (
      <Reports studentId={student.id} reportId={selectReportId} close={close} />
    ) : (
      <ReportList studentId={student.id} toReportDetail={toReportDetail} />
    );
  };

  return (
    <>
      <Layout>
        <div className={style.editFormWrapper}>
          <div className={style.studentPanel}>
            <div className={style.studentName}>
              {student.familyName}
              {student.firstName}
            </div>
          </div>
          <Tabs defaultActiveKey="report" fill className="report-tabs">
            <Tab
              eventKey="report"
              title={
                <>
                  <div className={style.tabIconHoukoku}>
                    <Ic iName="faFileLines" />
                  </div>
                  <div className={style.tabTitle}>指導報告</div>
                </>
              }
            >
              {loading ? <ListOrEditor /> : <></>}
            </Tab>
            <Tab
              eventKey="exam"
              title={
                <>
                  <div className={style.tabIconTest}>
                    <Ic iName="faPen" />
                  </div>
                  <div className={style.tabTitle}>テスト</div>
                </>
              }
            >
              {loading ? <Exams studentId={student.id} /> : <></>}
            </Tab>
            <Tab
              eventKey="grade"
              title={
                <>
                  <div className={style.tabIconSeiseki}>
                    <Ic iName="faChartLine" />
                  </div>
                  <div className={style.tabTitle}>成績</div>
                </>
              }
            >
              {loading ? <Records studentId={student.id} /> : <></>}
            </Tab>
          </Tabs>
        </div>
      </Layout>
    </>
  );
};

export default ReportEdit;
