import { Button } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { useModalScrollLock } from '@/util/useModalScrollLock';
import style from './SignBoard.scss';

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const modalId = 'signModal';

const SignBoard = ({
  readonly = false,
  prepareDoSign = false,
  initialImage = null,
  onChange = () => {},
  onSave = () => {},
}) => {
  const [show, setShow] = useState(false);
  useModalScrollLock({ isModalOpen: show, modalId });

  const handleClose = () => setShow(false);
  const handleSave = () => {
    setShow(false);
    onSave();
  };
  const handleShow = () => prepareDoSign && setShow(true);

  const [canvas, setCanvas] = useState(null);
  const [context, setContext] = useState(null);
  const [inDrag, setInDrag] = useState(false);
  const [lastXY, setLastXY] = useState(null);

  const [canvasWidth, setCanvasWidth] = useState(getWindowSize());
  const [canvasHeight, setCanvasHeight] = useState(getWindowSize());

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const stopScrollingBackContent = () => {
    document.body.style.overflow = show ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  };

  useEffect(stopScrollingBackContent, [show]);

  const handleWindowResize = () => {
    setCanvasWidth(getWindowSize().innerWidth - 60);
    setCanvasHeight(getWindowSize().innerHeight - 80);
  };

  useEffect(() => {
    draw();
  }, [canvasWidth, canvasHeight]);

  useEffect(() => {
    handleWindowResize();
    draw();
  }, [show]);

  const canvasRef = useCallback((canvasElement) => {
    if (canvasElement === null || canvasElement.current === null) {
      return;
    }
    setCanvas(canvasElement);
    if (context !== null) {
      return;
    }
    const ctx = canvasElement.getContext('2d');
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.lineWidth = 5;
    ctx.strokeStyle = '#000000';
    ctx.save();
    setContext(ctx);
  }, []);

  const draw = () => {
    if (initialImage === null) {
      return;
    }
    if (context === null) {
      return;
    }
    const sign = new Image();
    sign.src = initialImage;
    sign.onload = () => {
      if (context !== null) {
        context.drawImage(sign, 0, 0, canvas.width, canvas.height);
      }
    };
  };

  useEffect(() => {
    draw();
  }, [initialImage]);
  useEffect(() => {
    draw();
  }, [context]);

  const onTouchStart = (e) => {
    if (readonly) {
      return;
    }
    e.preventDefault();
    setLastXY(getLocationFromTouch(e));
    setInDrag(true);
  };

  const onTouchMove = (e) => {
    if (readonly) {
      return;
    }
    if (!inDrag) {
      return;
    }
    const xy = getLocationFromTouch(e);
    move(xy);
  };

  const onTouchEnd = (e) => {
    if (readonly) {
      return;
    }
    if (!inDrag) {
      return;
    }
    const xy = getLocationFromTouch(e);
    drawLine(context, lastXY, xy);
    endDraw();
  };

  const onMouseDown = (e) => {
    if (readonly) {
      return;
    }
    e.preventDefault();
    const lastXY = getMouseCursorLocationXY(e);
    setLastXY(lastXY);
    setInDrag(true);
  };

  const onMouseMove = (e) => {
    if (readonly) {
      return;
    }
    if (!inDrag) {
      return;
    }
    move(getMouseCursorLocationXY(e));
  };

  const onMouseUp = (e) => {
    if (readonly) {
      return;
    }
    if (!inDrag) {
      return;
    }
    drawLine(context, lastXY, getMouseCursorLocationXY(e));
    endDraw();
  };

  const endDraw = () => {
    if (readonly) {
      return;
    }
    setInDrag(false);
    onChange(canvas.toDataURL('image/png'));
  };

  const drawLine = (context, from, to) => {
    context.beginPath();
    context.moveTo(from.x, from.y);
    context.lineTo(to.x, to.y);
    context.stroke();
    context.closePath();
  };

  const getLocationFromTouch = (e) => {
    const touchObject = e.changedTouches[0];
    const touchX = touchObject.pageX;
    const touchY = touchObject.pageY;

    const clientRect = canvas.getBoundingClientRect();
    const positionX = clientRect.left + window.pageXOffset;
    const positionY = clientRect.top + window.pageYOffset;

    // 要素内におけるタッチ位置を計算
    return {
      x: touchX - positionX,
      y: touchY - positionY,
    };
  };

  const getMouseCursorLocationXY = (touchEvent) => {
    return {
      x: touchEvent.nativeEvent.offsetX,
      y: touchEvent.nativeEvent.offsetY,
    };
  };

  const move = (xy) => {
    drawLine(context, lastXY, xy);
    setLastXY(xy);
  };

  const clear = () => {
    context.clearRect(0, 0, canvas.width, canvas.height);
    onChange(null);
  };

  const canSign = () => {
    return canvasHeight < canvasWidth;
  };

  return (
    <>
      {!readonly && (
        <div className={style.buttonContainer}>
          <Button
            active={prepareDoSign}
            className={style.button}
            variant={prepareDoSign ? 'primary' : 'outline-dark'}
            onClick={handleShow}
          >
            署名をする
          </Button>
        </div>
      )}
      {readonly && (
        <>
          <div>ご署名</div>
          <img alt={'署名'} src={initialImage} className={style.signCanvas} width={'100%'} />
        </>
      )}
      {show && (
        <>
          <div id={modalId} className={style.modal}>
            <div className={style.modalBody}>
              {!canSign() && (
                <>
                  <div className={style.warningOrientation}>
                    <div className={style.warningOrientationMessage}>
                      スマホ・タブレットを横向きにしてご署名ください
                    </div>
                  </div>
                </>
              )}
              {canSign() && (
                <>
                  <canvas
                    ref={canvasRef}
                    className={style.signCanvas}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                    onMouseDown={onMouseDown}
                    onMouseMove={onMouseMove}
                    onMouseUp={onMouseUp}
                    width={canvasWidth}
                    height={canvasHeight}
                  ></canvas>
                </>
              )}
            </div>
            <div className={style.modalFooter}>
              <Button variant="secondary" onClick={handleClose}>
                閉じる
              </Button>
              <Button variant="secondary" onClick={clear}>
                クリア
              </Button>
              <Button variant="primary" onClick={handleSave}>
                保存
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SignBoard;
