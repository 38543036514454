import React from 'react';
import style from '@/components/contract/Contract.scss';

const GoodPrivacyPolicy = () => {
  return (
    <>
      <div className={style.memo}>
        <div>
          当社はお客様の個人情報をお預かりするにあたり、その取り扱いについて、当社個人情報保護方針に基づき、個人情報保護法及びその他関係法令を遵守し、下記のように管理し、保護に努めてまいります。
        </div>
        <div>
          <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>【個人情報の利用目的】</div>
          <div>
            当社は、お客様からご提供いただく情報を以下の目的の範囲内において、当社が提供するサービスに利用します。お客様の同意なく、目的外の利用を行うことはありません。
          </div>
          <div>
            <div>①ご本人確認②ご連絡③商品配送④契約関係の維持・管理⑤資料送付</div>
            <div>
              尚、②と④につきましては、お客様と弊社との間の契約関係が終了した後も、上記の利用目的の範囲内でお客様の情報を利用することがあります。
            </div>
          </div>
          <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>【第三者への提供】</div>
          <div>①商品の発送業務のため関連商品製造事業者への提供</div>
          <div>
            ②模擬試験を受験された場合、試験の結果については、生徒の氏名、学校名、学年及び、答案内容を模試主催者に提供
          </div>
          <div>
            上記の利用目的における第三者への提供以外は、法律で定められている場合を除いて、弊社がお客様の個人情報を当該本人の同意を得ず第三者に開示・提供・預託することはありません。
          </div>
          <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>【個人情報の取り扱い業務の委託】</div>
          <div>
            当社は事業運営上、業務の一部を外部に委託しており、業務委託先に対してお客様の人情報を預けることがあります。この場合、個人情報を適切に取り扱っていると認められる委託先を選定し、契約等において個人情報の適正管理・機密保持などにより
            お客様の個人情報の漏えい防止に必要な事項を取り決め、適切な管理を実施させま す。
          </div>
          <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
            【個人情報の開示・訂正・追加・削除等について】
          </div>
          <div>
            お客様にはご自身の個人情報の利用目的の通知、開示、訂正、追加、削除の要求、利用または提供の拒否をする権利があります。開示等が必要な場合には、下記の窓口まで連絡ください。
          </div>
          <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>【お申し出先】</div>
          <div>
            <div>〒542-0081</div>
            <div>大阪府大阪市中央区南船場4丁目11-17船場MKビル7F</div>
            <div>TEL:0120-887-844(無料電話)</div>
            <div>家庭教師のグッド　株式会社グッド</div>
            <div>「お客様あんしん相談窓口」担当</div>
            <div>
              個人情報保護方針については、
              <a rel={'noreferrer'} target={'_blank'} href={'https://k-good.net/'}>
                当社HP
              </a>
              で公表しております。
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GoodPrivacyPolicy;
